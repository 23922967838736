import { getInvoiceById, getInvoiceByToken } from '@/application/services/invoice'
import { extractParamsFromUrl } from '@/application/utils/tokens'
import { useRouter } from 'vue-router'

export const useInvoice = () => {
  const router = useRouter()
  const { token } = extractParamsFromUrl()
  const currentRoute = router.currentRoute.value
  const isInner = currentRoute.name === 'invoice'
  const id = isInner && currentRoute.params ? currentRoute.params.id : null
  return {
    getInvoice: token ? () => getInvoiceByToken(token) : () => getInvoiceById(id),
    isInner,
  }
}
