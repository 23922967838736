<template>
  <template v-if="!isLoading">
    <div class="card form-card mb-3 print d-print-block" id="invoice">
      <div class="row invoice-contact mb-0 mb-md-4">
        <div class="col-sm-8 col-md-7">
          <div class="invoice-box row">
            <div class="col-sm-12">
              <table class="table table-responsive invoice-table table-borderless p-l-20">
                <tbody>
                <tr>
                  <td class="d-flex align-items-center">
                    <a class="b-brand"
                       v-if="invoiceData.invoice_from.logo"
                       href="#"><img
                      class="img-fluid"
                      style="width: 50px;"
                      :src="invoiceData.invoice_from.logo"
                      alt="Gradient Able Logo"></a>
                    <span :class="{'pl-2': !!invoiceData.invoice_from.logo}">
                      {{invoiceData.invoice_from.name}}
                    </span>
                  </td>
                </tr>
                <tr><td>{{invoiceData.invoice_from.address}}</td></tr>
                <tr><td>{{invoiceData.invoice_from.country}}</td></tr>
                <tr v-if="invoiceData.invoice_from.hasOwnProperty('email')">
                  <td><a class="text-secondary" :href="`mailto:${invoiceData.invoice_from.email}`" target="_top">{{invoiceData.invoice_from.email}}</a></td>
                </tr>
                <tr v-if="invoiceData.invoice_from.hasOwnProperty('phone')">
                  <td>{{invoiceData.invoice_from.phone}}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="col-sm-4 col-md-5">
          <div class="invoice-box d-flex justify-content-start justify-content-md-end">
            <table class="table table-responsive invoice-table table-borderless p-l-0 p-r-20 mb-0 d-inline-block w-auto">
              <tbody>
              <tr v-if="invoiceData.invoice_from.logo" class="d-none d-sm-block">
                <td style="height: 50px; box-sizing: content-box;"></td>
              </tr>
              <tr>
                <td>{{$t('forms.invoice.invoice_number')}}: <span class="text-nowrap">{{invoiceData.invoice_no}}</span></td>
              </tr>
              <tr>
                <td>{{$t('forms.invoice.invoice_date')}}: <span class="text-nowrap">{{formatDate(invoiceData.date)}}</span></td>
              </tr>
              <tr>
                <td>{{$t('forms.invoice.status')}}: <span class="text-nowrap">{{getLocalizedOrValue($i18n, 'forms.billing.payment_statuses.' + invoiceData.status, invoiceData.status)}}</span></td>
              </tr>
              <tr v-if="invoiceData.due_date">
                <td>{{$t('forms.invoice.dateDue')}}: <span class="text-nowrap">{{formatDateTime(invoiceData.due_date)}}</span></td>
              </tr>
              <tr v-if="invoiceData.paid_date">
                <td>{{$t('forms.invoice.datePaid')}}: <span class="text-nowrap">{{formatDateTime(invoiceData.paid_date)}}</span></td>
              </tr>
              <tr class="noprint" v-if="invoiceData.status === 'unpaid' && invoiceData.pay_url">
                <td>
                  <button @click="pay(invoiceData.pay_url)" class="btn btn-success btn_payInvoice mt-2">{{$t('forms.invoice.pay')}}</button>
                  <template v-if="invoiceData.invoice_from.statement">
                    <p class="mt-2 mb-0">{{$t('forms.invoice.statement')}}: <span class="text-nowrap">{{invoiceData.invoice_from.statement}}</span></p>
                    <template v-if="invoiceData.invoice_from.statement_info">
                      <a target="_blank" :href="invoiceData.invoice_from.statement_info">{{$t('forms.invoice.who_is_paddle')}}</a> <br>
                    </template>
                    <a v-if="invoiceData.invoice_from.faq_url"
                       :href="invoiceData.invoice_from.faq_url"
                       target="_blank">{{$t('forms.invoice.bank_transfer_faq')}}</a> <br>
                  </template>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="row invoive-info">
          <div class="col-md-4 col-xs-12 invoice-client-info">
            <h6 class="text-uppercase">{{$t('forms.invoice.invoiced_to')}}:</h6>
            <h6 class="m-0">{{invoiceData.invoice_to.name}}</h6>
            <p class="m-0" v-if="invoiceData.invoice_to.company">{{invoiceData.invoice_to.company}}</p>
            <p class="m-0">{{invoiceData.invoice_to.address}}</p>
            <p class="m-0">{{invoiceData.invoice_to.city}} {{invoiceData.invoice_to.state}} {{invoiceData.invoice_to.post_code}}</p>
            <p class="m-0">{{invoiceData.invoice_to.country}}</p>
            <p class="m-0">{{invoiceData.invoice_to.phone}}</p>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="table-responsive">
              <table class="table invoice-detail-table">
                <thead>
                <tr class="thead-default">
                  <th>{{$t('forms.invoice.description')}}</th>
                  <th>{{$t('forms.invoice.quantity')}}</th>
                  <th>{{$t('forms.invoice.total')}}</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(item, key) in invoiceData.items" :key="key">
                  <td><h6>{{item.name}}</h6></td>
                  <td>{{item.quantity}}</td>
                  <td>{{item.localized_price}}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="row" v-if="invoiceData.payment">
          <div class="col-sm-12">
            <table class="table table-responsive invoice-table invoice-total mb-0">
              <tbody>
              <template v-if="invoiceData.payment.localized_tax">
                <tr>
                  <th>{{$t('forms.invoice.subtotal')}} :</th>
                  <td>{{invoiceData.payment.localized_price}}</td>
                </tr>
                <tr>
                  <th>{{$t('forms.invoice.tax')}} :</th>
                  <td>{{invoiceData.payment.localized_tax}}</td>
                </tr>
                <tr class="text-info">
                  <td>
                    <hr>
                    <h5 class="text-primary m-r-10">{{$t('forms.invoice.total')}} :</h5></td>
                  <td>
                    <hr>
                    <h5 class="text-primary">{{invoiceData.payment.localized_gross_price}}</h5></td>
                </tr>
              </template>
              <template v-else>
                <tr class="text-info">
                  <td>
                    <h5 class="text-primary m-r-10">{{$t('forms.invoice.total')}} :</h5></td>
                  <td>
                    <h5 class="text-primary">{{invoiceData.payment.localized_gross_price}}</h5></td>
                </tr>
              </template>
              </tbody>
            </table>
          </div>
        </div>
        <div class="row" v-if="invoiceData.status === 'unpaid'">
          <div class="col-sm-12">
            <table class="table table-responsive invoice-table invoice-total mb-0">
              <tbody>
              <tr class="text-info">
                <td>
                  <h5 class="text-primary m-r-10">{{$t('forms.invoice.total')}} : </h5></td>
                <td>
                  <h5 class="text-primary">{{invoiceData.localized_total_amount}} *</h5>
                </td>
              </tr>
              </tbody>
            </table>
            <small v-html="'*' + $t('forms.invoice.no_taxes')"></small>
          </div>
        </div>
        <p class="mt-3 mb-0 text-center" v-if="invoiceData.invoice_from.statement">{{$t('forms.invoice.statement')}}: <span class="text-nowrap">{{invoiceData.invoice_from.statement}}</span></p>
      </div>
    </div>
    <div class="row mb-3 noprint">
      <div class="col-12 text-center">
        {{$t('forms.invoice.support')}} <a href="mailto:support@loopmessage.com">support@loopmessage.com</a> <br>
        <p class="mt-1 mb-0" v-if="invoiceData.invoice_from.statement_info || invoiceData.invoice_from.faq_url">
          <a target="_blank"
             v-if="invoiceData.invoice_from.statement_info"
             :href="invoiceData.invoice_from.statement_info">{{ $t('forms.invoice.who_is_paddle') }}</a>
          <a v-if="invoiceData.invoice_from.faq_url"
             :href="invoiceData.invoice_from.faq_url"
             :class="{ 'ml-3': !!invoiceData.invoice_from.statement_info }"
             target="_blank">{{$t('forms.invoice.bank_transfer_faq')}}</a>
        </p>
      </div>
    </div>
    <div class="row text-center btn-page noprint">
      <div class="col-sm-12 invoice-btn-group text-center">
        <button type="button" class="btn btn-primary py-2 px-3" @click="print">Print</button>
      </div>
    </div>
  </template>
  <spinner-component v-if="isLoading" item-class="table-spinner-layout"></spinner-component>
</template>

<script>

import { defineAsyncComponent, onMounted, ref } from 'vue'
import { useInvoice } from '@/application/composables/invoiceForm'
import { useHumanDate } from '@/application/composables/humanDate'
import { getLocalizedOrValue } from '@/application/utils/localization'
import { useI18n } from 'vue-i18n'
import { initPaddle } from '@/application/utils/paddle'
import { useRouter } from 'vue-router'

export default {
  name: 'MessagingPage',
  components: {
    'spinner-component': defineAsyncComponent(() => import('@/application/components/layout/SpinnerComponent.vue')),
  },
  setup() {
    const { humanDate } = useHumanDate()
    const { getInvoice, isInner } = useInvoice()
    const router = useRouter()

    const i18n = useI18n()
    const formatDate = (date) => humanDate(date, i18n.locale.value)
    const formatDateTime = (date) => humanDate(date, i18n.locale.value)
    const invoiceData = ref({})
    const checkoutCompleted = ref(false)
    const isLoading = ref(true)
    onMounted(async() => {
      try {
        const { data } = await getInvoice()
        invoiceData.value = data
        isLoading.value = false
        initPaddle((data) => {
          if (data.event === 'Checkout.PaymentComplete') {
            checkoutCompleted.value = true
          } else if (data.event === 'Checkout.Close') {
            if (checkoutCompleted.value) {
              window.location.reload()
            }
          }
        })
      } catch (exception) {
        if (isInner) {
          router.push({ name: 'invoices' })
        } else {
          window.location.href = '/sign-in'
        }
      }
    })

    return {
      isLoading,
      invoiceData,
      humanDate,
      getLocalizedOrValue,
      formatDate,
      formatDateTime,
      pay: (url) => {
        window.Paddle.Checkout.open({
          override: url,
          disableLogout: true,
        })
      },
      print: () => {
        const title = document.title
        document.title = invoiceData.value.invoice_no
        window.print()
        document.title = title
      },
    }
  },
}
</script>
